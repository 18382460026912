<template>
  <q-page v-if="stash.summary" class="column content-center">
    <m-group-title :label="$t('select_ticket_delivery_option')" class="q-my-md" />
    <q-card
      v-if="eticketAvailable"
      class="cursor-pointer"
      :class="{
        'bg-primary-selected': eticketSelected,
        'bg-white': !eticketSelected,
        'text-faded': !eticketSelected,
        'text-primary': eticketSelected
      }"
      @click.native="selectEticket"
    >
      <q-card-section class="row">
        <div class="col-2 col-md-1">
          <q-icon name="fas fa-qrcode" left size="32px" />
        </div>
        <div class="col-10 col-md-11">
          <div class="text-h6">
            {{ $t('train.eticket_avoid_queues') }}
          </div>
          <div class="text-subtitle2 text-faded">
            {{ $t('train.tickets_attached_to_booking') }}
          </div>
        </div>
      </q-card-section>
    </q-card>

    <q-card
      class="cursor-pointer"
      :class="{
        'bg-primary-selected': !eticketSelected,
        'bg-white': eticketSelected,
        'text-faded': eticketSelected,
        'text-primary': !eticketSelected
      }"
      @click.native="selectCollection"
    >
      <q-card-section class="row">
        <div class="col-2 col-md-1">
          <q-icon name="fas fa-ticket-alt" left size="32px" />
        </div>
        <div class="col-10 col-md-11">
          <div class="text-h6">
            {{ $t('collect_ticket_from_station') }}
          </div>
          <div class="text-subtitle2 text-faded">
            {{ $t('train.tickets_collect_after_booking') }}
          </div>
        </div>
      </q-card-section>
    </q-card>

    <m-banner
      v-if="!eticketSelected && !hasKiosk"
      :title="$t('train.station_has_no_kiosk.title', { station_name: `${origin.name} (${origin.atoc})`})"
      class="help-notice"
      color="warning"
      icon="fas fa-exclamation-triangle"
    >
      <template v-slot:message>
        <div v-for="line in $t('train.station_has_no_kiosk.description')" :key="line" class="text-body2">
          {{ line }}
        </div>
        <div class="text-body2 text-link">
          <a :href="trainlineCollectionStationsURL" target="_blank">{{ $t('train.alternative_ticket_collection_stations') }}</a>
        </div>
      </template>
    </m-banner>

    <m-group-title :label="$t('train.seat_reservation')" class="q-my-md" />

    <seat-preferences
      :outbound-reservability="outboundReservability"
      :return-reservability="returnReservability"
      @change="val => selectSeatPrefs(val)"
    />

    <m-banner
      v-if="outboundReservability || returnReservability"
      :title="$t('train.cannot_guarantee_preferences')"
      class="help-notice padding"
      color="primary"
    />
    <div class="cta">
      <m-cta-button
        @ctaClick="$router.push({name: 'ondemand-train-summary'})"
      />
    </div>
  </q-page>
</template>

<script type="text/javascript">
import { handleErrors } from 'utils/utils'
import store from 'store'
import loading from 'utils/loading'
import { summary } from 'api/train'
import { mapGetters } from 'vuex'
import i18n from 'i18n'
import { MGroupTitle, MBanner, MCtaButton } from 'components/'
import SeatPreferences from './seat-preferences/options.vue'

export default {
  name: 'OndemandTrainOptions',
  components: { MGroupTitle, MBanner, MCtaButton, SeatPreferences },
  data () {
    return {
      reserve_outbound: false,
      reserve_return: false,
      options: {
        delivery: null,
        seat_preferences: []
      },
      trainlineCollectionStationsURL: 'https://business.support.thetrainline.com/en/support/solutions/articles/78000000022-which-stations-can-i-collect-my-tickets-from-in-the-uk-'
    }
  },
  computed: {
    ...mapGetters({
      stash: 'ondemand/stash'
    }),
    summary () {
      return this.stash.summary
    },
    eticketAvailable () {
      return this.summary.journeys.outbound.eticket_available
    },
    eticketSelected () {
      return this.options.delivery === 'ETicket'
    },
    outboundReservability () {
      return this.stash.summary.journeys.outbound.reservability
    },
    returnReservability () {
      return this.stash.summary.journeys?.return?.reservability
    },
    origin () {
      return this.summary.journeys.outbound.origin
    },
    hasKiosk () {
      return this.origin.has_kiosk
    }
  },
  methods: {
    selectEticket () {
      this.options.delivery = 'ETicket'
    },
    selectCollection () {
      this.options.delivery = 'Collection'
    },
    selectSeatPrefs (val) {
      this.reserve_outbound = val.reserve_outbound
      this.reserve_return = val.reserve_return
      if (!val.reserve_outbound && !val.reserve_return) {
        this.options.seat_preferences = []
        return
      }
      let seatPrefs = [ ...val.options.facilities ]
      if (val.options.direction !== null) {
        seatPrefs.push(val.options.direction)
      }
      if (val.options.carriage_type !== null) {
        seatPrefs.push(val.options.carriage_type)
      }
      if (val.options.position !== null) {
        seatPrefs.push(val.options.position)
      }
      this.options.seat_preferences = seatPrefs
    }
  },
  async beforeRouteEnter (to, from, next) {
    const partner = store.getters.partner
    const stash = store.getters['ondemand/stash']
    loading.start({
      message: i18n.t('loading.getting.ticket_summary'),
      partnerSlug: partner.slug,
      spinnerColor: partner.hex
    })

    try {
      const { data } = await summary(stash.selected.ticket.booking_token)
      next(vm => {
        store.dispatch('ondemand/stash', { summary: data })

        vm.options.delivery = vm.eticketAvailable ? 'ETicket' : 'Collection'
        vm.outbound_reservability = 'optional'
        vm.return_reservability = 'none'

        store.dispatch('ondemand/stash', { delivery: data.delivery_options })

        loading.stop()
      })
    } catch (err) {
      handleErrors(err)
      next(false)
      loading.stop()
    }
  },
  beforeRouteLeave (to, from, next) {
    store.dispatch('ondemand/stash',
      {
        ...this.options,
        reserve_outbound: this.reserve_outbound,
        reserve_return: this.reserve_return
      })
    next()
  }
}
</script>

<style lang="stylus" scoped>
.q-page
  max-width 720px
  margin auto
  padding 2em
.q-radio.flex
  display flex !important // Override display: inline-flex
.help-notice
  max-width 620px
  margin 16px auto
  a
    color $primary
    text-decoration underline
    font-style italic
  &.padding
    margin-bottom 100px // just a stupid amount of padding so it's not hidden by the MOnDemandFooter when on mobile
</style>
