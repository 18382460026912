<template>
  <q-page padding>
    <div v-if="summary" class="row">
      <q-card class="bg-white full-width">
        <q-card-section class="bg-primary">
          {{ $t('booking.summary') }}
        </q-card-section>
        <q-card-section>
          <div class="row">
            <sub class="col-12">
              {{ $t('train.summary.ticket_delivery') }}
            </sub>
            <div v-if="delivery && delivery.text === 'Collection'" class="col-12 text-grey-9 delivery-text">
              <h6 v-for="(collectionInstruction, i) in $t('train.summary.ticket_collection')" :key="`collectionInstruction ${i}`">
                {{ collectionInstruction }}
              </h6>
              <a :href="trainlineHowToCollectUrl" target="_blank">{{ $t('train.summary.where_collect') }}</a>
            </div>
            <h6 v-else class="col-12 text-grey-9 delivery-text">
              {{ delivery.text }}
            </h6>
          </div>
          <q-separator class="hr" />
          <rail-journey
            :journey="summary.journeys.outbound"
            :leg-name="$t('train.outbound')"
            :permitted-origin-stations="outBoundOriginStations"
            :permitted-destination-stations="outBoundDestinationStations"
          />
          <rail-journey
            v-if="summary.journeys.return"
            :journey="summary.journeys.return"
            :leg-name="$tc('one_way_return', 2)"
            :permitted-origin-stations="inBoundOriginStations"
            :permitted-destination-stations="inBoundDestinationStations"
          />
          <open-return-journey
            v-if="stash.parameters.return_type === 'open_return'"
            :origin="summary.journeys.outbound.destination.name"
            :destination="summary.journeys.outbound.origin.name"
            :valid-until="summary.tickets[0].inbound_valid_until"
          />
          <q-separator class="hr" />
          <ticket :ticket="summary.tickets[0]" />
          <ticket
            v-if="shouldDisplayReturnTicket"
            :ticket="summary.tickets[1]"
          />
          <q-item class="item adults">
            <q-item-label>
              <q-item-label label class="adult-text">
                {{ adults }}
              </q-item-label>
              <q-item-label label class="adult-text">
                {{ children }}
              </q-item-label>
            </q-item-label>
          </q-item>
          <q-separator class="hr" />

          <div class="price-breakdown">
            <div v-if="summary.total_unconcessioned_price && summary.total_unconcessioned_price.text" class="price-breakdown__unconcession">
              <div>
                {{ $t('booking.costs.ticket_price') }}
              </div>
              <div>
                {{ summary.total_unconcessioned_price.text }}
              </div>
            </div>

            <div
              v-if="promotionDiscountMinusGroupsave > 0"
              class="price-breakdown__railcard"
            >
              <div>
                {{
                  $t('booking.costs.promotion_discount', {
                    promotion: 'Promotion',
                  })
                }}
              </div>
              <div>-{{ summary.total_promotion_discount.text }}</div>
            </div>
            <div
              v-if="summary.total_groupsave_discount && summary.total_groupsave_discount.amount > 0"
              class="price-breakdown__railcard"
            >
              <div>
                {{
                  $t('booking.costs.promotion_discount', {
                    promotion: 'GroupSave',
                  })
                }}
              </div>
              <div>-{{ summary.total_groupsave_discount.text }}</div>
            </div>

            <div
              v-if="summary.total_railcard_discount && summary.total_railcard_discount.amount > 0"
              class="price-breakdown__railcard"
            >
              <div>
                {{
                  $t('booking.costs.railcard_discount', {
                    card: 'Railcard',
                  })
                }}
              </div>
              <div>-{{ summary.total_railcard_discount.text }}</div>
            </div>

            <div v-if="summary.mobilleo_booking_fee_with_vat && summary.mobilleo_booking_fee_with_vat.text" class="price-breakdown__booking-fee">
              <div>
                {{ $t('booking.costs.booking_fee_vat') }}
              </div>
              <div>{{ summary.mobilleo_booking_fee_with_vat.text }}</div>
            </div>
          </div>

          <q-separator class="hr" />

          <div v-if="summary.total_cost && summary.total_cost.text" class="price-breakdown text-bold">
            <div class="price-breakdown__total">
              <div>
                {{ $t('booking.costs.total') }}
              </div>
              <div>{{ summary.total_cost.text }}</div>
            </div>
          </div>
        </q-card-section>
      </q-card>

      <m-attach-to-booking-request
        :suggested="summary.suggested_journey_name"
        :username="stash.parameters.traveller.value"
        :journey="journey"
        :show-travel-type="travelPolicy && travelPolicy.rail.enabled === true"
        @change="(val) => $store.dispatch('ondemand/journey', val)"
        @reasonChange="(val) => (reason_for = val)"
        @labelChange="(val) => (labels = val)"
        @travelTypeChange="(val) => (travelType = val)"
      />

      <payment-buttons
        :total-amount="summary.total_cost.amount"
        :requester="stash.parameters.requester"
        :traveller="stash.parameters.traveller || stash.parameters.requester"
        :journey="journey"
        :reason-for="reason_for"
        :labels="labels"
        :booking-token="stash.selected.ticket.booking_token"
        :travel-type="
          (travelPolicy && travelPolicy.rail.enabled === true) || !organisation
            ? travelType
            : 'Private'
        "
      />
      <m-terms show-nationalrail />
    </div>
  </q-page>
</template>

<script type="text/javascript">
import { handleErrors } from 'utils/utils'
import store from 'store'
import authentication from 'mixins/authentication'
import { summary } from 'api/train'
import { mapGetters } from 'vuex'
import loading from 'utils/loading'
import paymentButtons from 'pages/payments/payment-method-buttons'
import railJourney from './rail-journey'
import openReturnJourney from './open-return-journey'
import ticket from './ticket'
import i18n from 'i18n'
import { MAttachToBookingRequest, MTerms } from 'components/'

export default {
  name: 'OndemandTrainSummary',
  components: {
    paymentButtons,
    railJourney,
    openReturnJourney,
    ticket,
    MAttachToBookingRequest,
    MTerms
  },
  mixins: [authentication],
  data () {
    return {
      summary: null,
      reason_for: null,
      labels: null,
      travelType: null,
      trainlineHowToCollectUrl: 'https://support.thetrainline.com/en/support/solutions/articles/78000000556-which-stations-can-i-collect-my-tickets-from-in-the-uk-'
    }
  },
  computed: {
    ...mapGetters({
      stash: 'ondemand/stash',
      journey: 'ondemand/journey',
      partner: 'partner',
      organisation: 'organisation'
    }),
    delivery () {
      const delivery = this.summary ? this.summary.delivery_options.find(option => {
        return option.text === this.stash.delivery
      }) : {}
      return delivery || {}
    },
    adults () {
      return this.$tc('num.adults', this.stash.parameters.adults)
    },
    children () {
      return this.$tc('num.children', this.stash.parameters.children)
    },
    travelPolicy () {
      if (!this.organisation) return null
      if (this.organisation?.attributes?.travel_policy) {
        return this.organisation.attributes.travel_policy
      }
      return { rail: { enabled: true } }
    },
    shouldDisplayReturnTicket () {
      const outboundTicket = this.summary.tickets[0]
      const returnTicket = this.summary.tickets[1]
      // If same ticket type, don't display return ticket
      return returnTicket && returnTicket.name !== outboundTicket.name
    },
    promotionDiscountMinusGroupsave () {
      /**
       * If no promotion discount, return 0
       * else if no groupsave discount, return promotion discount (including 0)
       * else return promotion discount (including 0) minus groupsave discount (including 0)
       *
       * Any 0 (or negative) number indicates we shouldn't show promotion discount
       */
      if (!this.summary.total_promotion_discount) {
        return 0
      } else if (!this.summary.total_groupsave_discount) {
        return this.summary.total_promotion_discount.amount
      } else {
        return this.summary.total_promotion_discount.amount - this.summary.total_groupsave_discount.amount
      }
    },
    outBoundOriginStations () {
      return this.summary.permitted_stations?.outbound_origin_stations || null
    },
    outBoundDestinationStations () {
      return this.summary.permitted_stations?.outbound_destination_stations || null
    },
    inBoundOriginStations () {
      return this.summary.permitted_stations?.inbound_origin_stations || null
    },
    inBoundDestinationStations () {
      return this.summary.permitted_stations?.inbound_destination_stations || null
    }

  },
  async mounted () {
    const { delivery, selected, seat_preferences, reserve_outbound, reserve_return } = store.getters['ondemand/stash']
    const partner = store.getters['partner']
    loading.start({
      message: i18n.t('loading.getting.ticket_summary'),
      partnerSlug: partner.slug,
      spinnerColor: partner.hex
    })

    try {
      const params = {
        delivery_method: delivery,
        collection_station: selected.depart.origin.atoc,
        seat_preferences,
        reserve_outbound,
        reserve_return
      }

      const { data } = await summary(selected.ticket.booking_token, params)

      this.summary = data
      loading.stop()
    } catch (err) {
      handleErrors(err)
      loading.stop()
    }
  }
}
</script>

<style lang="stylus" scoped>
button
  width 100%

span
  margin-top 16px

.hr
  margin-top 1rem
  margin-bottom 1rem

.item
  padding-left 0

.delivery-text
  margin-bottom 0
  a
    color $primary
    text-decoration underline
    font-style italic

.adults
  padding-bottom 0
  min-height 0

.adult-text
  font-size 14px !important

.price-breakdown
  display grid
  grid-template-columns 1fr
  gap .5rem

  *
    display grid
    gap .5rem
    grid-template-columns 1fr max-content

    :nth-child(1)
      text-align right
      color $grey-8
</style>
