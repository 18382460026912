<template>
  <div class="row" style="margin-top: 16px">
    <p class="col-12">
      <strong>{{ origin }}</strong>
    </p>
    <p>{{ $t('to') }}</p>
    <p class="col-12">
      <strong>{{ destination }}</strong>
    </p>
    <p class="col-12 text-grey-7">
      <span class="leg-name">OPEN RETURN</span>
      <span class="leg-date">{{ returnValidity }}</span>
    </p>
  </div>
</template>

<script>
import date from 'utils/date-time'
const { subtractFromDate, toHugeDate } = date

export default {
  props: ['origin', 'destination', 'validUntil'],
  computed: {
    returnValidity () {
      if (this.validUntil) {
        return ` - Ticket Valid until ${toHugeDate(subtractFromDate(this.validUntil, { days: 1 }))}`
      } else {
        return ` - Return within one month`
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.leg-name
  text-transform uppercase
  font-weight 700
  font-size 15px

.leg-date
  color black
  font-size 13px
</style>
