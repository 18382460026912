<template>
  <div class="ticket-container">
    <q-card
      v-ripple
      :class="classes"
      class="cursor-pointer"
      @click="$emit('click', ticket)"
    >
      <q-card-section class="row no-padding">
        <div class="col" style="padding: 16px; display: flex; place-content: center flex-start">
          <span>{{ cardTitle }}</span>
        </div>
        <div
          class="col-2 col-xs-4 column justify-center items-center content-center text-center bg-white"
        >
          <sub class="text-grey-7">{{
            $t('total_cost_for_passengers', { num: stash.parameters.adults + stash.parameters.children })
          }}</sub>
          <sub v-if="isGroupsaveDiscount" class="text-grey-7">with a Groupsave discount</sub>
          <sub v-if="isRailcardDiscount" class="text-grey-7">with a Railcard discount</sub>
          <span v-if="showOriginalPrice" class="text-h5 text-grey-6 text-strikethrough">{{ ticket.total_unconcessioned_price.text }}</span>
          <span class="text-h5">{{ ticket.total_price.text }}</span>
        </div>
      </q-card-section>
    </q-card>

    <q-btn flat color="primary" icon="info" @click.native="() => modal = true" />
    <terms :show-terms="modal" :tickets="ticket.tickets" @hide="() => modal = false" />
  </div>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex'
import Terms from './terms.vue'

export default {
  components: { Terms },
  props: {
    ticket: null
  },
  data () {
    return {
      modal: false
    }
  },
  computed: {
    ...mapGetters({
      stash: 'ondemand/stash'
    }),
    isSelected () {
      return this.stash.selected.ticket === this.ticket
    },
    classes () {
      return this.isSelected ? 'selected bg-primary-selected text-primary' : ''
    },
    showOriginalPrice () {
      return this.ticket.total_price.amount !== this.ticket.total_unconcessioned_price.amount
    },
    isGroupsaveDiscount () {
      return this.ticket.tickets[0].groupsave_discount.amount > 0
    },
    isRailcardDiscount () {
      return this.ticket.tickets[0].railcard_discount.amount > 0
    },
    cardTitle () {
      const ticketNames = this.ticket.tickets.map(ticketItem => ticketItem.name)
      const title = ticketNames.join(' + ')
      return title
    }
  }
}
</script>

<style lang="stylus" scoped>
  .q-card
    max-width 600px
    position relative

    @media (max-width 599px)
      width 100%
      margin 0
      border-bottom solid 1px #ccc
  .text-h5
    font-size 1.3em
  .ticket-container
    display: grid
    grid-template-columns: 1fr auto

  // Because Quasar sucks immensely
  ::v-deep .q-focus-helper
    background: none !important
  .text-strikethrough
    text-decoration line-through
    text-decoration-thickness 1.5px

</style>
