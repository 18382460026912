var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.stash.summary)?_c('q-page',{staticClass:"column content-center"},[_c('m-group-title',{staticClass:"q-my-md",attrs:{"label":_vm.$t('select_ticket_delivery_option')}}),(_vm.eticketAvailable)?_c('q-card',{staticClass:"cursor-pointer",class:{
      'bg-primary-selected': _vm.eticketSelected,
      'bg-white': !_vm.eticketSelected,
      'text-faded': !_vm.eticketSelected,
      'text-primary': _vm.eticketSelected
    },nativeOn:{"click":function($event){return _vm.selectEticket.apply(null, arguments)}}},[_c('q-card-section',{staticClass:"row"},[_c('div',{staticClass:"col-2 col-md-1"},[_c('q-icon',{attrs:{"name":"fas fa-qrcode","left":"","size":"32px"}})],1),_c('div',{staticClass:"col-10 col-md-11"},[_c('div',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.$t('train.eticket_avoid_queues'))+" ")]),_c('div',{staticClass:"text-subtitle2 text-faded"},[_vm._v(" "+_vm._s(_vm.$t('train.tickets_attached_to_booking'))+" ")])])])],1):_vm._e(),_c('q-card',{staticClass:"cursor-pointer",class:{
      'bg-primary-selected': !_vm.eticketSelected,
      'bg-white': _vm.eticketSelected,
      'text-faded': _vm.eticketSelected,
      'text-primary': !_vm.eticketSelected
    },nativeOn:{"click":function($event){return _vm.selectCollection.apply(null, arguments)}}},[_c('q-card-section',{staticClass:"row"},[_c('div',{staticClass:"col-2 col-md-1"},[_c('q-icon',{attrs:{"name":"fas fa-ticket-alt","left":"","size":"32px"}})],1),_c('div',{staticClass:"col-10 col-md-11"},[_c('div',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.$t('collect_ticket_from_station'))+" ")]),_c('div',{staticClass:"text-subtitle2 text-faded"},[_vm._v(" "+_vm._s(_vm.$t('train.tickets_collect_after_booking'))+" ")])])])],1),(!_vm.eticketSelected && !_vm.hasKiosk)?_c('m-banner',{staticClass:"help-notice",attrs:{"title":_vm.$t('train.station_has_no_kiosk.title', { station_name: ((_vm.origin.name) + " (" + (_vm.origin.atoc) + ")")}),"color":"warning","icon":"fas fa-exclamation-triangle"},scopedSlots:_vm._u([{key:"message",fn:function(){return [_vm._l((_vm.$t('train.station_has_no_kiosk.description')),function(line){return _c('div',{key:line,staticClass:"text-body2"},[_vm._v(" "+_vm._s(line)+" ")])}),_c('div',{staticClass:"text-body2 text-link"},[_c('a',{attrs:{"href":_vm.trainlineCollectionStationsURL,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t('train.alternative_ticket_collection_stations')))])])]},proxy:true}],null,false,1894575038)}):_vm._e(),_c('m-group-title',{staticClass:"q-my-md",attrs:{"label":_vm.$t('train.seat_reservation')}}),_c('seat-preferences',{attrs:{"outbound-reservability":_vm.outboundReservability,"return-reservability":_vm.returnReservability},on:{"change":function (val) { return _vm.selectSeatPrefs(val); }}}),(_vm.outboundReservability || _vm.returnReservability)?_c('m-banner',{staticClass:"help-notice padding",attrs:{"title":_vm.$t('train.cannot_guarantee_preferences'),"color":"primary"}}):_vm._e(),_c('div',{staticClass:"cta"},[_c('m-cta-button',{on:{"ctaClick":function($event){return _vm.$router.push({name: 'ondemand-train-summary'})}}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }