<template>
  <q-drawer
    v-model="show"
    elevated
    side="right"
  >
    <div
      v-if="stash.selected.depart"
      class="column items-stretch"
    >
      <q-stepper
        v-if="!this.$q.platform.is.mobile"
        ref="stepper"
        v-model="currentStep"
        done-color="primary"
        vertical
        class="row"
        header-nav
      >
        <q-step
          name="ondemand-train-depart"
          default
          :title="$t('when_are_you_departing')"
          :header-nav="navigatable.includes('ondemand-train-depart')"
          :done="navigatable.includes('ondemand-train-depart')"
        >
          <q-btn
            v-if="stash.return"
            @click="$router.push({name: 'ondemand-train-return'})"
          >
            {{ $t('continue') }}
          </q-btn>
          <q-btn
            v-else
            @click="$router.push({name: 'ondemand-train-tickets'})"
          >
            {{ $t('continue') }}
          </q-btn>
        </q-step>
        <q-step
          v-if="stash.return"
          name="ondemand-train-return"
          :title="$t('when_are_you_coming_back')"
          :header-nav="navigatable.includes('ondemand-train-return')"
          :done="navigatable.includes('ondemand-train-return')"
        >
          <q-btn @click="$router.push({name: 'ondemand-train-tickets'})">
            {{ $t('continue') }}
          </q-btn>
        </q-step>
        <q-step
          name="ondemand-train-tickets"
          :title="$t('train.choose.ticket_type')"
          :header-nav="navigatable.includes('ondemand-train-tickets')"
          :done="navigatable.includes('ondemand-train-tickets')"
        >
          <q-btn v-if="stash.selected.ticket" @click="$router.push({name: 'ondemand-train-options'})">
            {{ $t('continue') }}
          </q-btn>
        </q-step>
        <q-step
          name="ondemand-train-options"
          :title="$t('train.options.tickets')"
          :header-nav="navigatable.includes('ondemand-train-options')"
          :done="navigatable.includes('ondemand-train-options')"
        >
          <q-btn @click="$router.push({name: 'ondemand-train-summary'})">
            {{ $t('continue') }}
          </q-btn>
        </q-step>
        <q-step
          name="ondemand-train-summary"
          :title="$t('summary')"
          :caption="$t('review_and_submit')"
          :header-nav="navigatable.includes('ondemand-train-summary')"
          :done="navigatable.includes('ondemand-train-summary')"
        />
        <q-step
          name="ondemand-train-payment"
          :title="$t('payment')"
          :header-nav="navigatable.includes('ondemand-train-payment')"
          :done="navigatable.includes('ondemand-train-payment')"
        />
        <q-step
          name="ondemand-train-confirmation"
          :title="$t('confirmation')"
          :header-nav="navigatable.includes('ondemand-train-confirmation')"
          :done="navigatable.includes('ondemand-train-confirmation')"
        />
      </q-stepper>
      <q-list
        v-if="$route.name === 'ondemand-train-depart' || $route.name === 'ondemand-train-return'"
        no-border
      >
        <q-item-label header>
          {{ $t('train.options.options') }}
        </q-item-label>

        <q-item>
          <q-item-section>
            <q-item-label caption class="text-center q-pb-md">
              {{ $t('change_times') }}
            </q-item-label>
            <div class="times">
              <q-btn
                :loading="loadingearlier"
                :disabled="disableearlier"
                color="primary"
                class="time-button"
                @click="() => changeResults('earlier')"
              >
                {{ $t('time.earlier') }}
              </q-btn>
              <q-btn
                :loading="loadinglater"
                :disabled="disablelater"
                color="primary"
                class="time-button"
                @click="() => changeResults('later')"
              >
                {{ $t('time.later') }}
              </q-btn>
            </div>
          </q-item-section>
        </q-item>
      </q-list>
    </div>
  </q-drawer>
</template>

<script type="text/javascript">
import { editSearch } from 'api/train'
import { mapGetters } from 'vuex'
export default {
  name: 'TrainSidebar',
  props: {
    leg: {
      type: String,
      validator: (value) => ['depart', 'return'].includes(value)
    }
  },
  data () {
    return {
      show: !this.$q.platform.is.mobile,
      disableearlier: false,
      disablelater: false,
      loadingearlier: false,
      loadinglater: false
    }
  },
  computed: {
    ...mapGetters({
      stash: 'ondemand/stash',
      ui: 'ui'
    }),
    currentStep: {
      get () {
        return this.$route.name
      },
      set (step) {
        this.$router.push({ name: step })
      }
    },
    navigatable () {
      let availablePaths = []
      switch (this.$route.name) {
      case 'ondemand-train-confirmation':
        availablePaths.push('ondemand-train-payment')
      case 'ondemand-train-payment':
        availablePaths.push('ondemand-train-summary')
      case 'ondemand-train-summary':
        availablePaths.push('ondemand-train-options')
      case 'ondemand-train-options':
        availablePaths.push('ondemand-train-tickets')
      case 'ondemand-train-tickets':
        availablePaths.push('ondemand-train-return')
      case 'ondemand-train-return':
        availablePaths.push('ondemand-train-depart')
        break
      }
      return availablePaths
    }
  },
  watch: {
    direction () {
      this.disableearlier = false
      this.disablelater = false
    }
  },
  methods: {
    async changeResults (direction) {
      this.$store.dispatch('ondemand/stash', { loading: true })
      this['loading' + direction] = true
      const search = this.stash.searchToken
      const params = {
        include: this.stash.parameters.return ? 'outbound.segments,return.segments' : 'outbound.segments'
      }

      try {
        const { data } = await editSearch(search, this.leg, direction, params)

        // If we leave the route while we're still loading, don't go any further
        if (!['ondemand-train-depart', 'ondemand-train-return'].includes(this.$route.name)) return

        const newResults = {
          depart: data.outbound,
          return: data.return || null,
          searchToken: data.search_token
        }

        this.$store.dispatch('ondemand/stash', newResults)
        this.$store.dispatch('ondemand/stash', { loading: false })

        this.disableearlier = false
        this.disablelater = false
        this['loading' + direction] = false
      } catch (err) {
        if (err.data.title === 'TrainlineBusinessErrorException') {
          this['disable' + direction] = true
          this['loading' + direction] = false
          this.$store.dispatch('ondemand/stash', { loading: false })
        }
        this.$q.notify({
          message: this.$t('error.search_token_expired'),
          type: 'negative'
        })
        this.$router.push('/on-demand/trains')
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  .overview-price
    padding 8px
    .total
      font-size 1em
    .price
      margin 4px 0
      font-size 1.6em
    .people
      font-size 0.8em
  .q-item-sublabel
    text-align center
    margin-bottom 15px
  .times
    padding 30px
    padding-top 0
    justify-content center
    display flex
    button
      &:first-of-type
        margin-right 15px

.time-button
  width 86px
</style>
