<template>
  <q-card>
    <template v-if="outboundReservability !== 'none' || returnReservability !== 'none'">
      <q-card-section
        class="row"
      >
        <div class="col-12 col-md-4">
          <div class="text-capitalize text-weight-medium">
            {{ $t('train.options.position') }}
          </div>
          <div class="options-column">
            <q-radio v-model="value.options.position" :val="null" :label="$t('any')" />
            <q-radio
              v-for="item in seat_prefs_list.position"
              :key="item.code"
              v-model="value.options.position"
              :val="item.code"
              :label="$t(`train.options.seat_prefs.position.${item.code}`)"
              class="flex"
            />
          </div>
        </div>
        <div class="col-12 col-md-4">
          <div class="text-capitalize text-weight-medium">
            {{ $t('train.options.direction') }}
          </div>
          <div class="options-column">
            <q-radio v-model="value.options.direction" :val="null" :label="$t('any')" />
            <q-radio
              v-for="item in seat_prefs_list.direction"
              :key="item.code"
              v-model="value.options.direction"
              :val="item.code"
              :label="$t(`train.options.seat_prefs.direction.${item.code}`)"
              class="flex"
            />
          </div>
        </div>
        <div class="col-12 col-md-4">
          <div class="text-capitalize text-weight-medium">
            {{ $t('train.options.carriage_type') }}
          </div>
          <div class="options-column">
            <q-radio v-model="value.options.carriage_type" :val="null" :label="$t('any')" />
            <q-radio
              v-for="item in seat_prefs_list.carriage_type"
              :key="item.code"
              v-model="value.options.carriage_type"
              :val="item.code"
              :label="$t(`train.options.seat_prefs.carriage_type.${item.code}`)"
              class="flex"
            />
          </div>
        </div>
      </q-card-section>
      <q-separator />
      <q-card-section>
        <div class="options-row">
          <q-checkbox
            v-for="item in seat_prefs_list.facilities"
            :key="item.code"
            v-model="value.options.facilities"
            :val="item.code"
            :label="$t(`train.options.seat_prefs.facilities.${item.code}`)"
            dense
          />
        </div>
      </q-card-section>
      <q-separator />
      <q-card-section>
        <div class="options-row">
          <q-card-section style="padding: 0">
            <q-checkbox
              v-model="value.reserve_outbound"
              :val="true"
              :label="$t(`train.options.reserve_seat_outbound`)"
              :disable="outboundReservability !== 'optional'"
              dense
              style="margin-bottom: 5px"
            />
            <div v-if="outboundReservability === 'mandatory'" class="text-subtitle2 text-faded">
              {{ $t('train.preferences_mandatory') }}
            </div>
            <div v-if="outboundReservability === 'none'" class="text-subtitle2 text-faded">
              {{ $t('train.preferences_unavailable_outbound') }}
            </div>
          </q-card-section>
          <q-card-section style="padding: 0">
            <q-checkbox
              v-if="hasReturnJourney"
              v-model="value.reserve_return"
              :val="true"
              :label="$t(`train.options.reserve_seat_return`)"
              :disable="returnReservability !== 'optional'"
              dense
              style="margin-bottom: 5px"
            />
            <div v-if="hasReturnJourney && returnReservability === 'mandatory'" class="text-subtitle2 text-faded">
              {{ $t('train.preferences_mandatory') }}
            </div>
            <div v-if="hasReturnJourney && returnReservability === 'none'" class="text-subtitle2 text-faded">
              {{ $t('train.preferences_unavailable_return') }}
            </div>
          </q-card-section>
        </div>
      </q-card-section>
    </template>
    <q-card-section v-else>
      {{ $t('train.preferences_unavailable') }}
    </q-card-section>
  </q-card>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SeatPreferencesOptions',
  props: {
    value: {
      type: Object,
      default () {
        return {
          reserve_outbound: false,
          reserve_return: false,
          options: {
            direction: null,
            position: null,
            carriage_type: null,
            facilities: []
          }
        }
      }
    },
    outboundReservability: {
      type: String,
      default: 'none'
    },
    returnReservability: {
      type: String,
      default: 'none'
    }
  },
  computed: {
    ...mapGetters({
      stash: 'ondemand/stash'
    }),
    seat_prefs_list () {
      return this.stash.summary?.seat_prefs_list || []
    },
    hasReturnJourney () {
      return !!this.stash.summary.journeys?.return
    }
  },
  watch: {
    value: {
      deep: true,
      handler (value) {
        this.$emit('change', value)
      }
    }
  },
  mounted () {
    this.value.reserve_outbound = this.outboundReservability === 'mandatory'
    this.value.reserve_return = this.returnReservability === 'mandatory'
  }
}
</script>
<style lang="stylus">
.options-row
  display grid
  gap .25em
  margin .5em 0
  grid-auto-flow column

.options-column
  display grid
  gap .25em
  margin .5em 0
</style>
