<template>
  <div class="row" style="margin-top: 16px">
    <p class="col-12">
      <strong>{{ journey.origin.name }}</strong>
      <q-icon v-if="filteredPermittedOriginStations.length > 0" name="info" color="primary" size="20px" class="info-icon" tag="span" aria-hidden="false" tabindex="0">
        <q-tooltip content-class="bg-transparent" self="top left" :offset="[0, -30]">
          <div class="tooltip-text">
            {{ $t('train.summary.permitted_from') }}
            <ul>
              <li v-for="station in filteredPermittedOriginStations" :key="station">
                {{ station }}
              </li>
            </ul>
          </div>
        </q-tooltip>
      </q-icon>
    </p>
    <p>{{ $t('to') }}</p>
    <p v-if="viaStationLabel && containsViaStation">
      {{ $t('via_place', { place: viaStationLabel }) }}
    </p>
    <p class="col-12">
      <strong>{{ journey.arrives.name }}</strong>
      <q-icon v-if="filteredPermittedDestinationStations.length > 0" name="info" color="primary" size="20px" class="info-icon" tag="span" aria-hidden="false" tabindex="0">
        <q-tooltip content-class="bg-transparent" self="top left" :offset="[0, -30]">
          <div class="tooltip-text">
            {{ $t('train.summary.permitted_to') }}
            <ul>
              <li v-for="station in filteredPermittedDestinationStations" :key="station">
                {{ station }}
              </li>
            </ul>
          </div>
        </q-tooltip>
      </q-icon>
    </p>
    <p class="col-12 text-grey-7">
      <span class="leg-name">{{ legName }}</span>
      <span class="leg-date"> - {{ journey.depart.date | date }}</span>
    </p>

    <h6 class="col-11 times">
      {{ journey.depart.date | time }}
      <q-icon name="arrow_forward" class="arrow" />
      {{ journey.arrive.date | time }}
    </h6>
    <span class="col-11 text-grey-7 leg-info">
      <a>{{ $tc('num.changes', journey.number_of_changes) }}</a>
      , {{ journey.duration.text }}
    </span>
  </div>
</template>

<script>
import date from 'utils/date-time'
import { mapGetters } from 'vuex'
export default {
  filters: {
    date: value => date.toHugeDate(value),
    time: value => date.toCivilTime(value)
  },
  props: ['journey', 'legName', 'permittedOriginStations', 'permittedDestinationStations'],
  computed: {
    ...mapGetters({
      stash: 'ondemand/stash'
    }),
    viaStationLabel () {
      return this.stash.parameters.via?.label
    },
    containsViaStation () {
      return this.stash.selected.depart.segments.data?.some(segment => {
        return segment.calling_at?.some(station => station.atoc === this.stash.parameters.via?.value)
      })
    },
    filteredPermittedOriginStations () {
      return this.permittedOriginStations?.filter(station => station !== this.journey.origin.name) ?? []
    },
    filteredPermittedDestinationStations () {
      return this.permittedDestinationStations?.filter(station => station !== this.journey.destination.name) ?? []
    }
  }
}
</script>

<style lang="stylus" scoped>
.location-info
  margin-bottom 8px
.location-info .destination
  font-size 20px

.leg-name
  text-transform uppercase
  font-weight 700
  font-size 15px

.leg-date
  color black
  font-size 13px

.times
  font-size 20px

.arrow
  vertical-align bottom

.leg-info
  font-size 13px

.info-icon
  margin-bottom 4px
  margin-left 8px
  cursor pointer

.tooltip-text
  margin-left 12px
  font-weight 700
  font-size 16px
  background-color white
  color #444
  border 1px solid #444
  border-radius 8px
  padding 4px 12px 4px 8px

  ul
    list-style none
    margin 8px
    padding-left 12px
</style>
