<template>
  <q-dialog v-model="localShowModal" @hide="hideModal">
    <q-card>
      <q-card-section>
        <div class="text-h6 terms-title">
          {{ $t('train.summary.ticket_terms') }}
        </div>
      </q-card-section>
      <q-card-section v-for="ticket in filteredTickets" :key="ticket.id">
        <q-list no-border>
          <div class="ticket-name text-h6">
            {{ ticket.name }}
          </div>
          <q-item v-for="(t, i) in ticket.terms" :key="i">
            <q-item-section>
              <q-item-label label>
                {{ t.name }}
              </q-item-label>
              <q-item-label caption>
                {{ t.description }}
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script type="text/javascript">
export default {
  props: {
    showTerms: null,
    tickets: null
  },
  data () {
    return {
      localShowModal: this.showTerms
    }
  },
  computed: {
    filteredTickets () {
      // Filter tickets that are the same as the first ticket
      const firstTicketName = this.tickets[0].name
      const filteredTickets = this.tickets.slice(1).filter(ticket => ticket.name !== firstTicketName)
      return [this.tickets[0], ...filteredTickets]
    }
  },
  watch: {
    showTerms (newVal) {
      this.localShowModal = newVal
    }
  },
  methods: {
    hideModal () {
      this.localShowModal = false
      this.$emit('hide')
    }
  }
}
</script>

  <style lang="stylus" scoped>
    .q-card
      max-width 600px
      position relative

      @media (max-width 599px)
        width 100%
        margin 0
        border-bottom solid 1px #ccc

    .terms-title
      padding: 16px 8px 0px 8px

    .ticket-name
      padding: 8px 16px

  </style>
